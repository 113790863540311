import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GrowthBook } from "@growthbook/growthbook";
import { DataService } from './data-sharing.service';
import { MixpanelService } from './mixpanel.service';
import { WindowRef } from './window.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Merchant } from '../models/merchant-config.model';
import { UTM } from '../../features/listing/listing.model';
import { ActivatedRoute } from '@angular/router';
import mixpanel from "mixpanel-browser";
export const growth_book = new GrowthBook({
  trackingCallback: (experiment, result) => {
    mixpanel.track("$experiment_started", {
      "Experiment name": experiment.key,
      "Variant name": result.variationId,
      $source: "growthbook",
    });
  },
  // enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
  enableDevMode: environment.ENABLE_FEATURE_FLAG_DEV_MODE,
});

export enum feature_flag_key {
  video_menu = 'video-menu',
  new_select_outlet = 'new_select_outlet',
  upsells_on_menu = 'upsells_on_menu',
  savings_message = 'savings-message',
  referral_feature = 'referral_feature',
  item_sharing_option = 'item_sharing_option',
  microsite_outlet_page = 'microsite-outlet-page',
  customisation_sort = 'customisation_sort',
  multi_outlet_page='multi_outlet_page'
};

@Injectable({
  providedIn: 'root'
})
export class FeaturesFlagService implements OnDestroy {
  growth_book_subscription$: any;
  logged_in_subscription$: any;
  growth_api_key: string = environment.GROWTH_BOOK_KEY;
  merchant_info: Merchant;
  utm: UTM = {};
  constructor(private http: HttpClient,
    private gtmService: GoogleTagManagerService,
    private dataService: DataService,
    private mixpanelService: MixpanelService,
    private winRef: WindowRef,
    private route: ActivatedRoute) {
    this.growth_book_subscription$ = growth_book.subscribe((experiment, result) => {
      this.gtmService.pushTag({
        'event': 'dimension4',
        'experiment': experiment.key + ":" + result.variationId,
      });
    });
  }

  getFeatureFlags() {
    this.http.get('https://cdn.growthbook.io/api/features/' + this.growth_api_key).subscribe((response: any) => {
      growth_book.setFeatures(response.features);
      this.dataService.setData('fetchedFeatureData',true);
    }, (error) =>
      this.mixpanelService.trackEvents('api_error', { message: error })
    );
  }

  setAttributes(merchant_details: Merchant) {
    this.route.queryParams.subscribe(params => {
      this.utm.utm_source = params['utm_source'];
      this.utm.utm_campaign = params['utm_campaign'];
      this.utm.utm_medium = params['utm_medium'];
    });
    this.merchant_info = merchant_details;
    this.logged_in_subscription$ = this.dataService.isLoggedIn.subscribe((status: boolean) => {
      let user_attributes: any = {
        id: this.mixpanelService.getDistinctId(),
        loggedIn: status,
        // city:this.merchant_info.city,//update by mixpamnel
        browser: this.winRef.nativeWindow.userAgent,
        url: location.href,
        utmSource: this.utm.utm_source ? this.utm.utm_source : '',
        utmMedium: this.utm.utm_medium ? this.utm.utm_medium : '',
        utmCampaign: this.utm.utm_campaign ? this.utm.utm_campaign : '',
        chain_id: this.merchant_info.chain_id ? this.merchant_info.chain_id : '',
        merchant_id: this.merchant_info.id
      };
      growth_book.setAttributes(user_attributes);
    });

  }
  ngOnDestroy(): void {
    this.growth_book_subscription$.unsubscribe();
    this.logged_in_subscription$.unsubscribe();
  }

}
