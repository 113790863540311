import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataService } from '../services/data-sharing.service';
import mixpanel from 'mixpanel-browser';
import { isPlatformBrowser } from '@angular/common';
import { Merchant } from '../models/merchant-config.model';
import { growth_book } from './features-flag.service';
import { ErrorHandlerService } from './error-handler.service';

export const MixpanelEvents = {
  'page_view': 'Page View',
  'ordering_type': 'Select Ordering Type',
  'add': 'Add to Cart',
  'remove': 'Remove from Cart',
  'detail': 'Item Detail View',
  'view_cart': 'View Cart',
  'initiate_checkout': 'Initiate Checkout',
  'initiate_payment': 'Initiate Payment',
  'purchase': 'Purchase',
  'current_location_initiate': 'Initiate Current Location',
  'current_location_success': 'Current Location Success',
  'current_location_fail': 'Current Location Fail',
  'cant_get_loc_name_frm_coord': 'Cannot get location name from coords',
  'steps_to_enable_loc_permission': 'Steps to Enable Location Permission',
  'manual_location_initiate': 'Initiate Search Location',
  'manual_location_success': 'Search Location Success',
  'manual_location_fail': 'Search Location Fail',
  'update_location': 'Update Location',
  'choose_searched_add': 'Choose Searched Add',
  'select_address': 'Select Address',
  'update_address': 'Update Address',
  'saved_address_list': 'Opened saved addresses list',
  'choose_outlets_list': 'Opened choose outlets list',
  'select_outlet': 'Select Outlet',
  'serviceability_zero_state': 'Serviceability zero state pop-up shown',
  'signup': 'Complete Registration',
  'login': 'Login',
  'logout': 'Logout',
  'select_time_slot': 'Select Time Slot',
  'view_offer': 'View Offer',
  'view_loyalty_reward': 'View Loyalty Reward',
  'apply_offer': 'Apply Offer',
  'search': 'Search',
  'scroll_list': 'Scroll List',
  'view_category': 'View Category',
  'click_link': 'Click Link',
  'add_instruction': 'Add instruction',
  'update_payment_method': 'Update Payment Method',
  'api_error': 'Error message',
  'sort': 'Sort applied',
  'filter': 'Filter applied',
  'item_liked': 'Item Liked',
  'item_shared': 'Item Shared',
  'cant_share_item': 'Cannot share item',
  'restaurant_shared': 'Restaurant Shared',
  'cant_share_restaurant': 'Cannot share restaurant',
  'view_store': 'View Store',
  'item_video_viewed': 'Item video viewed',
  'offer_auto_applied': 'Offer Auto Applied',
  'google_geoloc_detected': 'Google Geolocation Detected',
  'browser_geoloc_detected': 'Browser Geolocation Detected',
  'internet_connection': 'Internet Connection',
  'global_error_handler': 'Global Error Handler',
  'upsell_viewed': 'Upsell Viewed',
  'upsell_added': 'Upsell Added',
  'restaurant_referral_viewed': 'Restaurant Referral Viewed',
  'bestseller': 'BestSeller/50% Orders',
  'auto_fill_otp': 'Auto Fill OTP',
  'multioutlet_home_page':'Multioutlet Home Page Viewed',
  'initate_open_chatbot':'Initiate Open Chatbot',
  'chatbot_opened':'Chatbot Opened',
  'chatbot_notopened':'Chatbot Not Opened',
  'failed_to_load_zohobot_script':'Failed to load zohobot script',
  'browser_storage_error':'Cannot read storage',
  'cant_copy_frm_clipboard':'Cannot copy from clipboard',
  'order_frm_nearest_outlet_cta_clicked':'Order from nearest outlet CTA clicked'
};

export interface PurchaseEvent {
  'cart_id': string | number,
  'items': any,
  'offers'?: string,
  'gross_amount': number | string;
  'address_id'?: string,
  'discount'?: string | number,
  'visit_id': string | number,
  'payment_method_type': string;
  'payment_method_id'?: string | number,
  'ordering_type': string;
};

export interface OfferEvent {
  'id'?: string | number,
  'type'?: string,
  'visibility'?: string,
  'discount'?: string | number,
  'promocode'?: string,
  'status'?: string;
};

export interface ViewCategoryEvent {
  'category_id'?: string | number,
  'category_name': string,
  'action': string;
};

export interface AddressEvent {
  'address_id': string | number,
  'address_type': string,
  'pincode': string | number,
  'locality': string,
  'full_address': string,
  'action'?: string;
};

export interface PaymentEvent {
  'cart_id': string | number,
  'payment_method_type': string;
  'payment_method_id'?: string | number;
};

export interface UserProperties {
  '$name': string,
  '$phone': string | number,
  '$email': string,
  'dob'?: string,
  'user_saved_locations'?: Array<number>,
  'merchants_visited'?: Array<number>; //location needs to be appended
};

@Injectable({
  providedIn: 'root'
})

export class MixpanelService extends ErrorHandlerService implements OnDestroy {
  merchantSubscription$: any = null;
  merchant_details: any = {
    merchant_chain_id: null,
    merchant_outlet_id: null
  };
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private dataService: DataService) {
    super(); // Call the superclass constructor
    if (isPlatformBrowser(this.platformId)) {
      mixpanel.init(environment.MIXPANEL_ID, {
        debug: true,
        ignore_dnt: true,
        loaded: function (mx) {
          growth_book.setAttributes({
            ...growth_book.getAttributes(),
            id: mx.get_distinct_id(),
          });
        }
      });
      this.initialise();
    }
  }

  ngOnDestroy() {
    if (this.merchantSubscription$) {
      this.merchantSubscription$.unsubscribe();
    }
  }

  initialise() {
    this.merchantSubscription$ = this.dataService.merchantDataReceived.subscribe((merchant: Merchant) => {
      if (merchant && Object.keys(merchant)?.length) {
        this.merchant_details.merchant_chain_id = merchant.chain_id ? merchant.chain_id : null;
        this.merchant_details.merchant_outlet_id = merchant.id;
        mixpanel.people.append('merchants_visited', merchant.id);
        // this.setSuperProperties(this.merchant_details);
      }
    });
  }

  handleStorageError(error: any, key: string): void {
    if (error instanceof DOMException && (error.name === 'QuotaExceededError' || error.name === 'SecurityError')) {
      // console.warn(`Storage operation failed: ${error.message}`);
      this.trackEvents('browser_storage_error', {
        error: error.message,
        error_name: error.name,
        key: key,
        type: 'Storage operation failed'
      });
    } else {
      // console.warn(`Unexpected storage error: ${error.message}`);
      this.trackEvents('browser_storage_error', {
        error: JSON.stringify(error),
        key: key,
        type: 'Unexpected storage error'
      });
    }
  }

  getDistinctId() {
    return mixpanel.get_distinct_id();
  }

  getPropertyVallue(name: string) {
    return mixpanel.get_property(name);
  }

  setSuperProperties(super_prop_obj: any) {
    mixpanel.register(super_prop_obj);
  }

  identifyUser(user_id: string) {
    mixpanel.identify(user_id);
  }

  setUserProfileProp(user: any) {
    let user_properties: UserProperties = {
      $name: user.name,
      $phone: user.mobile,
      $email: user.email
    };
    if (user.date_of_birth) {
      user_properties.dob = user.date_of_birth;
    }
    if (user.address && user.address.length) {
      user_properties.user_saved_locations = user.address.map((obj) => {
        return obj.id;
      });
    }
    mixpanel.people.set(user_properties);
  }

  trackEvents(name: string, event_obj?: any) {
    event_obj = event_obj ? event_obj : {};
    Object.assign(event_obj, this.merchant_details);
    mixpanel.track(MixpanelEvents[name], event_obj);
  }

  trackAddressEvents(name: string, address: any) {
    let address_event_obj: AddressEvent = {
      address_id: address.id,
      address_type: address.address_type,
      pincode: address.pincode,
      locality: address.building_name,
      full_address: address.delivery_area,
    };
    if (address.action) {
      address_event_obj.action = address.action;
    }
    Object.assign(address_event_obj, this.merchant_details);
    mixpanel.track(MixpanelEvents[name], address_event_obj);
  }

  trackUserLocationEvents(name: string, location: any) {
    let location_event_obj: any = {
      lat: location.lat,
      lon: location.lon,
      locality: location.address_name
    };
    Object.assign(location_event_obj, this.merchant_details);
    mixpanel.track(MixpanelEvents[name], location_event_obj);
  }

  trackLinks(id: string, source: string) {
    mixpanel.track_links(id, MixpanelEvents.click_link, { source: source });
  }

}
