import { Injectable } from '@angular/core';
import { WindowRef } from './window.service';
import { MixpanelService } from './mixpanel.service';
import { DataService } from './data-sharing.service';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';


@Injectable()
export class LocationService {
  constructor( private winRef: WindowRef,
    private mixpanelService:MixpanelService,
    private apiService: ApiService,
    private dataService:DataService) {}

  //function to get lat/lon from the browser Geolocation API
  getCurrentCoordinates(): Promise<GeolocationCoordinates> {
    return new Promise((resolve, reject) => {
      this.winRef.nativeWindow.navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getCurrentLocation(page:string): Promise<GeolocationCoordinates> {
    return new Promise((resolve, reject) => {
      this.mixpanelService.trackEvents('current_location_initiate',{'page':page});
      if (this.dataService.getDataFromStore('opened_on_paytm_mini_app')) {
        const requestObject = {
          permission: 'location'
        };
        this.winRef.nativeWindow.JSBridge.call('paytmCheckPermission', requestObject, (result: any) => {
          if (result?.data?.location) {
            this.getCurrentCoordinates().then((coords: GeolocationCoordinates) => {
              resolve(coords);
            }).catch((error) => {
              reject(error);
            });
          } else if ((result.error && result.errorMessage === "not implemented!") || !result?.data?.location) {
            this.winRef.nativeWindow.JSBridge.call('paytmRequestPermission', requestObject, (response: any) => {
            });
          }
        });
      } else {
        this.getCurrentCoordinates().then((coords: GeolocationCoordinates) => {
          resolve(coords);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  //get location details from coordinates
  getLocationDetailsFrmCoords(coords:GeolocationCoordinates,page:string): Observable<any>{
    this.mixpanelService.trackEvents('current_location_success',{'page':page});
    return this.apiService.getData('/contactless_dining/api/v3/maps/reverse_geocode?lat=' + coords.latitude + '&lon=' + coords.longitude, null);
  }

  //handle location errors in case coordinates can't be fetched
  handleLocationError(error:GeolocationPositionError|string,page:string):any{
    let err_obj:any={};
    let err_msg:string=null;
    let alert_msg:string=null;
    if (typeof error === 'string') {
      err_msg=error;
    }else{
      switch(error.code) {
        case error.PERMISSION_DENIED:
          err_msg="User denied the request for Geolocation.";
          alert_msg='Please allow location access from Settings > Site settings > Location';
          break;
        case error.POSITION_UNAVAILABLE:
          err_msg="Location information is unavailable.";
          let ua = this.winRef.nativeWindow.navigator.userAgent || this.winRef.nativeWindow.navigator.vendor;
          if((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)){
            let os:number=0;
            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(ua)) {
              os=1;
            }else if (/android/i.test(ua)) {
              os=2;
            }else if (/iPad|iPhone|iPod/.test(ua) && !this.winRef.nativeWindow.MSStream) {
              os=3;
            }
            err_obj.mod_info={
              heading:'',
              description:'',
              button_text:''
            };
            if(os){
              err_obj.mod_info.heading="Set device location";
              err_obj.mod_info.button_text="Okay, got it!";
              err_obj.mod_info.description="Please change your app permissions to detect your current location.";
              if(os===3){
                err_obj.mod_info['description2']="Tap iOS Settings —> Tap Location —> Select Option";
              }else{
                err_obj.mod_info['description2']=`Open Settings —> Search for Facebook
                  —> Permissions —> Turn on Location Permission`;
              }
            }
          }else{
            if(error.message){
              alert_msg=error.message;
            }
          }
          break;
        case error.TIMEOUT:
          err_msg="The request to get user location timed out.";
          break;
        default:
          err_msg=error.message?error.message:"An unknown error occurred.";
          break;
      }
    }
    this.mixpanelService.trackEvents('current_location_fail',{'reason':err_msg,'page':page});
    err_obj['err_msg']=err_msg;
    err_obj['alert_msg']=alert_msg;
    return err_obj;
  }
}