import {ErrorHandler, Injectable} from '@angular/core';
import { WindowRef } from '../services/window.service';
import { MixpanelService } from '../services/mixpanel.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from "@sentry/angular-ivy";
// import {environment } from '../../../environments/environment';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private winRef: WindowRef,
    private mixpanelService:MixpanelService){
  }
  
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    // Check if the error message matches the chunk loading error pattern
    if (error?.message && chunkFailedMessage.test(error.message)) {
      this.winRef?.nativeWindow?.location?.reload();
      return;
    } 

    // Track the error event with Mixpanel
    this.mixpanelService.trackEvents('global_error_handler', { error: error?.message || 'Unknown error occurred' });
    
    if (error instanceof HttpErrorResponse) {
      // if (this.ignoreError(error)) {
      //   return;
      // }
      const errorMessage = `HTTP Error: ${error?.status || 'Unknown Status'} - ${JSON.stringify(error.error) || 'No Message'}`;
      const errorDetails = error?.url ? `${errorMessage} - URL: ${error.url}` : errorMessage;
      // Capture HTTP errors with additional context
      Sentry.withScope(scope => {
        scope.setContext("http_error", {
          status: error?.status,
          url: error?.url,
          message: error?.error
        });
        Sentry.captureException(new Error(errorDetails));
      });
    } else {
      Sentry.captureException(error);
    }
  }
  // private ignoreError(error: any): boolean {
  //   if (error?.error?.error_message === 'Merchant not present!' && error?.status === 404) {
  //     console.log('error coming');
  //     return true;
  //   }
  //   return false;
  // }
}