import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { WindowRef } from './window.service';
import { DOCUMENT } from '@angular/common';
import { MixpanelService } from './mixpanel.service';

export interface ZohoChatInitData {
    user_id:string,
    user_name:string,
    user_mobile:string,
    order_id:string|number,
    order_status:string,
    merchant_name:string,
    merchant_slug:string
};

@Injectable({
  providedIn: 'root'
})
export class ZohoChatbotService {
  private renderer: Renderer2;
  private retryInterval: any; // Interval object for retry

  constructor(rendererFactory: RendererFactory2, 
    private winRef: WindowRef,
    @Inject(DOCUMENT) private doc: Document,
    private mixpanelService:MixpanelService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadZohoChatbot(callback: (loaded: boolean)=> void): void {
    if (!this.isScriptLoaded('zsiqchat')) {
      const script = this.renderer.createElement('script');
      script.id = 'zsiqchat';
      script.src = '../../../assets/scripts/add-zoho-salesiq.js'; 

      script.onload = () => {
        callback(false);
      };

      script.onerror = () => {
        this.mixpanelService.trackEvents('failed_to_load_zohobot_script');
      };

      this.renderer.appendChild(this.doc.head, script);
    } else {
      callback(true);
    }
  }

  private isScriptLoaded(scriptId: string): boolean {
    return !!this.doc.getElementById(scriptId);
  }

  private isChatWindowOpen(): boolean {
    if (document) {
      const chatWindow = document.querySelector('div[data-id="zsiqembed"]');
      if (chatWindow && chatWindow.classList.contains('siqanim')) {
          return true;
      }else{
          return false;
      }
    }
    return false;
  }

  openLastConversation(details: ZohoChatInitData){
    this.winRef.nativeWindow.$zoho.salesiq.visitor.name(details.user_name);
    this.winRef.nativeWindow.$zoho.salesiq.visitor.contactnumber(details.user_mobile);
    this.winRef.nativeWindow.$zoho.salesiq.visitor.info({
        order_id: details.order_id,
        order_status: details.order_status,
        user_id: details.user_id,
        user_name: details.user_name,
        user_phone: details.user_mobile,
        merchant_name: details.merchant_name,
        merchant_slug: details.merchant_slug
    });
    this.winRef.nativeWindow.$zoho.salesiq.chat.start();
  }

  openZohoChatbot(details: ZohoChatInitData): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        const checkInterval = 2000; // Check every 500ms
        const timeout = 30000; // Timeout after 30 seconds
        let elapsed = 0;

        const intervalId = setInterval(() => {
        const { $zoho, $ZSIQChatWindow } = this.winRef.nativeWindow;

        if (this.isChatWindowOpen()) {
            clearInterval(intervalId);
            resolve(true);
            return;
        }

        if ($zoho && $zoho.salesiq && $zoho.salesiq.visitor) {
            // Set visitor information
            $zoho.salesiq.visitor.name(details.user_name);
            $zoho.salesiq.visitor.contactnumber(details.user_mobile);
            $zoho.salesiq.visitor.info({
                order_id: details.order_id,
                order_status: details.order_status,
                user_id: details.user_id,
                user_name: details.user_name,
                user_phone: details.user_mobile,
                merchant_name: details.merchant_name,
                merchant_slug: details.merchant_slug
            });
            if (typeof $ZSIQChatWindow !== 'undefined') {

                // Ensure the chat window is visible
                $zoho.salesiq.floatwindow.visible('show');
                // Start the chat
                $zoho.salesiq.chat.start();
                clearInterval(intervalId);
                resolve(true);
                return;
            }
        }

        elapsed += checkInterval;
        if (elapsed >= timeout) {
            clearInterval(intervalId);
            reject(new Error('ZohoSalesIQ is not available.'));
        }
        }, checkInterval);
    });
  }
}