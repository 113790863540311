import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartGuard } from './utility/guards/cart.guard';

const routes: Routes = [
  {
    path: 'not-found',
    data: { title: 'Order Online | Not Found' },
    loadChildren: () => import('./features/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: 'error-unavailable',
    data: { title: 'Order Online | Closed for Orders' },
    loadChildren: () => import('./features/error-unavailable/error-unavailable.module').then(m => m.ErrorUnavailableModule)
  },
  {
    path: 'error-500',
    data: { title: 'Order Online | Error 500' },
    loadChildren: () => import('./features/error-500/error-500.module').then(m => m.Error500Module)
  },
  {
    path: 'order',
    data: {
      title: ' Order Food Online Delivery | Thrive',
      description: 'Thrive enables you to order food directly from restaurants you love! #SayNoToZomatoSwiggy'
    },
    loadChildren: () => import('./features/listing/listing.module').then(m => m.ListingModule)
  },
  {
    path: 'order/:listing_slug',
    data: { title: 'Order Food Online Delivery | Thrive x Collab', description: '' },
    loadChildren: () => import('./features/brand-collaboration/brand-collaboration.module').then(m => m.BrandCollaborationModule)
  },
  {
    path: ':slug',
    data: { title: '' },
    loadChildren: () => import('./features/homepage/homepage.module').then(m => m.HomePageModule)
  },
  {
    path: ':slug/setup/delivery',
    data: { title: '' },
    loadChildren: () => import('./features/homepage/homepage.module').then(m => m.HomePageModule)
  },
  {
    path: ':slug/select-outlet/:ordering_type',
    data: { title: 'Order Online', description: 'Select Outlet' },
    loadChildren: () => import('./features/multi-outlet-home/multi-outlet-home.module').then(m => m.MultiOutletHomeModule)
  },
  {
    path: ':slug/choose-brand/:ordering_type',
    data: { title: 'All Outlets Order Online', description: 'Choose Brand' },
    loadChildren: () => import('./features/choose-brand/choose-brand.module').then(m => m.ChooseBrandModule)
  },
  {
    path: ':slug/choose-category/:ordering_type',
    data: { title: 'Choose Category' },
    loadChildren: () => import('./features/choose-category/choose-category.routes').then((m) => m.CHOOSE_CATEGORY_ROUTES)
  },
  {
    path: ':slug/choose-subchain/:ordering_type',
    data: { title: 'Choose Subchain' },
    loadChildren: () => import('./features/choose-subchain/choose-subchain.routes').then((m) => m.CHOOSE_SUBCHAIN_ROUTES)
  },
  {
    path: ':slug/browse-menu/:ordering_type',
    data: { title: 'Order Online' },
    loadChildren: () => import('./features/browse-menu/browse-menu.module').then(m => m.BrowseMenuModule)
  },
  {
    path: ':slug/browse-menu/:ordering_type/:table_ids',
    data: { title: 'Order Online' },
    loadChildren: () => import('./features/browse-menu/browse-menu.module').then(m => m.BrowseMenuModule)
  },
  {
    path: ':slug/login/:ordering_type',
    data: { title: 'Order Online | Login' },
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
    // canActivate: [CartGuard]
  },
  {
    path: ':slug/signup/:ordering_type',
    data: { title: 'Order Online | Sign-up' },
    loadChildren: () => import('./features/signup/signup.module').then(m => m.SignupModule),
    // canActivate: [CartGuard]
  },
  {
    path: ':slug/signup/:ordering_type/:id',
    data: { title: 'Order Online | Sign-up' },
    loadChildren: () => import('./features/signup/signup.module').then(m => m.SignupModule),
    // canActivate: [CartGuard]
  },
  {
    path: ':slug/view-order/:ordering_type',
    data: { title: 'Order Online | View Cart' },
    loadChildren: () => import('./features/your-order/your-order.module').then(m => m.YourOrderModule),
    canActivate: [CartGuard]
  },
  {
    path: ':slug/place-order/dine_in',
    data: { title: 'Order Online | View Cart' },
    loadChildren: () => import('./features/place-order/place-order.module').then(m => m.PlaceOrderModule),
    // canActivate: [CartGuard]
  },
  {
    path: ':slug/place-order/dine_in/:id',
    data: { title: 'Order Online | View Cart' },
    loadChildren: () => import('./features/place-order/place-order.module').then(m => m.PlaceOrderModule),
    // canActivate: [CartGuard]
  },
  {
    path: ':slug/settle-bill/dine_in/:id',
    data: { title: 'Order Online | Settle Bill' },
    loadChildren: () => import('./features/settle-bill/settle-bill.module').then(m => m.SettleBillModule),
    // canActivate: [CartGuard]
  },
  {
    path: ':slug/processing-payments/:ordering_type',
    data: { title: 'Processing payments' },
    loadChildren: () => import('./features/payments/processing-payment/processing-payment.routes').then((m) => m.PROCESSING_PAYMENTS_ROUTES)
  },
  {
    path: ':slug/order-status/success/dine_in/:id',
    data: { title: 'Order Online | Order Placed' },
    loadChildren: () => import('./features/order-status/order-status.module').then(m => m.OrderStatusModule)
  },
  {
    path: ':slug/order-status/success/:ordering_type/:id',
    data: { title: 'Order Online | Confirm Order' },
    loadChildren: () => import('./features/order-confirmation-error/order-confirmation-error.module').then(m => m.OrderConfirmationErrorModule)
  },
  {
    path: ':slug/order-status/error/dine_in',
    data: { title: 'Order Online | Error' },
    loadChildren: () => import('./features/order-status/order-status.module').then(m => m.OrderStatusModule)
  },
  {
    path: ':slug/order-status/error/:ordering_type',
    data: { title: 'Order Online | Error' },
    loadChildren: () => import('./features/order-confirmation-error/order-confirmation-error.module').then(m => m.OrderConfirmationErrorModule)
  },
  {
    path: ':slug/payment-status/success/dine_in/:id',
    data: { title: 'Order Online | Confirm Order' },
    loadChildren: () => import('./features/payment-status/payment-status.module').then(m => m.PaymentStatusModule)
  },
  {
    path: ':slug/payment-status/error/dine_in',
    data: { title: 'Order Online | Payment Error' },
    loadChildren: () => import('./features/payment-status/payment-status.module').then(m => m.PaymentStatusModule)
  },
  {
    path: ':slug/order-summary/:ordering_type/:id',
    loadChildren: () => import('./features/order-summary/order-summary.module').then(m => m.OrderSummaryModule)
  },
  {
    path: ':slug/table-order-status/dine_in/:id',
    data: { title: 'Order Online | View Status' },
    loadChildren: () => import('./features/table-order-status/table-order-status.module').then(m => m.TableOrderStatusModule)
  },
  {
    path: ':slug/track-order/:id',
    data: { title: 'Order Online | Track Order' },
    loadChildren: () => import('./features/track-order/track-order.module').then(m => m.TrackOrderModule)
  },
  {
    path: ':slug/faq',
    data: { title: 'Order Online | FAQs' },
    loadChildren: () => import('./features/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: ':slug/profile',
    data: { title: 'Order Online | Profile' },
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
    // canActivate: [CartGuard]
  },
  {
    path: ':slug/feedback/:id',
    data: { title: 'Feedback' },
    loadChildren: () => import('./features/feedback/feedback.routes').then((m) => m.FEEDBACK_ROUTES)
  },
  {
    path: '',
    loadChildren: () => new Promise(() => {
      window.location.href = 'https://about.thrivenow.in/';
    })
  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
    // scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
