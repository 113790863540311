<div [ngClass]="{'container':checkURL()}" [@routeAnimations]="prepareRoute(outlet)">
  <app-loader></app-loader>
  <router-outlet #outlet="outlet"></router-outlet>
  <!-- common modal -->
  <jw-modal [details]="{'heading':common_mod_info?.heading,
      info_btn:common_mod_info?.button_text,
      info_btn_msg:common_mod_info?.button_msg,
      info_btn_cancel:common_mod_info?.button_text_cancel,
      full_btn:common_mod_info?.full_btn,
      hide_cross:common_mod_info?.hide_cross
    }"
    [id]="'commonModal'"
    (onClick)="closeCommonModal($event)">
    <p class="center">{{common_mod_info?.description}}</p>
  </jw-modal>
</div>
