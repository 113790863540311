import { Injectable } from '@angular/core';
import {  HttpInterceptor,  HttpRequest,  HttpResponse, HttpHandler,  HttpEvent,  HttpErrorResponse, HttpParameterCodec, HttpParams } from '@angular/common/http';
import {  Observable, throwError  } from 'rxjs';
import {  map,  catchError  } from 'rxjs/operators';
import { DataService } from './data-sharing.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { MixpanelService } from '../../utility/services/mixpanel.service';

const error_status:Array<number>=[404,422,500];
@Injectable()

export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private dataService:DataService,
    private loaderService: LoaderService,
    private mixpanelService:MixpanelService) {}


  intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
    let token: any = this.dataService.getDataFromStore('user_auth_token');
    this.loaderService.show();
    request = request.clone({
      headers: request.headers.set('x-api-key', 'c862759292123471f04301753c9604ce77a43ae905eb09f9bb395dc8db3caaf381635d22f32c2dd19c2696b2950b853e317ca0e7578b1eadf07daa68416d63e4')
    });
    if(token&&token?.length){
      request = request.clone({ headers: request.headers.set('Authorization',token)});
    }
    const params = new HttpParams({encoder: new CustomEncoder(), fromString: request.params.toString()});
    request = request.clone({params});
    return next.handle(request).pipe(
      map((event: HttpEvent < any > ) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if(error_status.indexOf(error.status)>-1){
          let msg:string=error.error&&error.error.message?error.error.message:'Oops! Something went wrong. We apologise for the inconvenience.';
          this.mixpanelService.trackEvents('api_error',{message:msg, triggerPoint:'interceptor'});
        }
        this.loaderService.hide();
        return throwError(error);
      })

    );
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}