import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorHandlerService } from './error-handler.service';

class LocalStorage implements Storage {
  [name: string]: any;
  readonly length: number = 0;
  clear(): void {}
  getItem(key: string): string | null { return null; }
  key(index: number): string | null { return null; }
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

class SessionStorage implements Storage {
  [name: string]: any;
  readonly length: number = 0;
  clear(): void {}
  getItem(key: string): string | null { return null; }
  key(index: number): string | null { return null; }
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

@Injectable()
export class DataService {
  private merchantData: BehaviorSubject<any> = new BehaviorSubject({});
  merchantDataReceived: Observable<any> = this.merchantData.asObservable();
  private menuData: BehaviorSubject<any> = new BehaviorSubject({});
  menuDataReceived: Observable<any> = this.menuData.asObservable();
  private loggedInStatus: BehaviorSubject<boolean> = new BehaviorSubject(null);
  isLoggedIn: Observable<boolean> = this.loggedInStatus.asObservable();
  private fetchedFeatureData: BehaviorSubject<boolean> = new BehaviorSubject(false);
  featureDataReceived: Observable<boolean> = this.fetchedFeatureData.asObservable();
  private storage: Storage;
  private session_storage: Storage;

  constructor(private errorHandlerService: ErrorHandlerService) {
    this.storage = this.initializeStorage(localStorage);
    this.session_storage = this.initializeStorage(sessionStorage);
  }

  private initializeStorage(storageType: Storage): Storage {
    try {
      const testKey = '__storage_test__';
      storageType.setItem(testKey, testKey);
      storageType.removeItem(testKey);
      return storageType;
    } catch (e) {
      if (e instanceof DOMException && (e.name === 'QuotaExceededError' || e.name === 'SecurityError')) {
        // console.warn(`${storageType} is not available: ${e.message}`);
      }
      this.errorHandlerService.handleStorageError(e, 'initializeStorage');
      return storageType instanceof LocalStorage ? new LocalStorage() : new SessionStorage();
    }
  }

  setData(key: string, value: any) {
    this[key].next(value);
  }

  // Set data to local storage
  setDataInStore(key: string, value: any) {
    try {
      const data = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
      this.storage.setItem(key, data);
    } catch (e) {
      this.errorHandlerService.handleStorageError(e, key);
    }
  }

  // Get data from local storage
  getDataFromStore(key: string): any {
    try {
      const item = this.storage.getItem(key);
      if (item) {
        const data = decodeURIComponent(escape(atob(item)));
        return JSON.parse(data);
      }
    } catch (e) {
      this.errorHandlerService.handleStorageError(e, key);
      this.storage.removeItem(key);
    }
    return null;
  }

  // Remove particular data from local storage
  removeDataFromStore(key: string) {
    try {
      this.storage.removeItem(key);
    } catch (e) {
      this.errorHandlerService.handleStorageError(e, key);
    }
  }

  // Clear local storage
  clearDataInStore() {
    try {
      this.storage.clear();
    } catch (e) {
      this.errorHandlerService.handleStorageError(e, 'clear data');
    }
  }

  // Set data to session storage
  setDataInSession(key: string, value: any) {
    try {
      const data = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
      this.session_storage.setItem(key, data);
    } catch (e) {
      this.errorHandlerService.handleStorageError(e, key);
    }
  }

  // Get data from session storage
  getDataFromSession(key: string): any {
    try {
      const item = this.session_storage.getItem(key);
      if (item) {
        const data = decodeURIComponent(escape(atob(item)));
        return JSON.parse(data);
      }
    } catch (e) {
      this.errorHandlerService.handleStorageError(e, key);
      this.session_storage.removeItem(key);
    }
    return null;
  }

  // Remove particular data from session storage
  removeDataFromSession(key: string) {
    try {
      this.session_storage.removeItem(key);
    } catch (e) {
      this.errorHandlerService.handleStorageError(e, key);
    }
  }
}