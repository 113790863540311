// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  RAILS_API: 'https://massiveapi.hashtagloyalty.net',
  GTM_ID:'GTM-T6DF9BK',
  WEBSITE_URL:'https://hashtagloyalty.net/',
  DASHBOARD_URL:'https://dashboard.hashtagloyalty.net',
  SENTRY_ENV:'development',
  GOOGLE_PLACES_KEY:'AIzaSyBg6_XOfMggNLHwzNc2uXjJXI2dqMHAJTA',
  MIXPANEL_ID:'bff22b91a34c8085718ced6414c15777',
  GROWTH_BOOK_KEY:'staging_jC1F2rgxbuUmsho3RBKlGub5274v2NFvefSuCphZ8Y',
  ENABLE_FEATURE_FLAG_DEV_MODE:true,
  ASSETS_DOMAIN_URL:'https://cdn.hashtagloyalty.net'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
