

//modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule ,ErrorHandler} from "@angular/core";
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from '../environments/environment';
import { ModalModule } from './shared/modal';
import * as Sentry from "@sentry/angular-ivy";
import { GlobalErrorHandler } from './utility/handlers/global-error.handler';
//components
import { AppComponent } from './app.component';
import { LoaderComponent } from './shared/loader/loader.component';
//services
import { DataService } from './utility/services/data-sharing.service';
import { CookieService } from './utility/services/cookie.service';
import { ThemeService } from './utility/services/theme.service';
import { ApiService } from './utility/services/api.service';
import { LoaderService } from './shared/loader/loader.service';
import { HttpConfigInterceptor } from './utility/services/interceptor.service';
import { MetaService} from './utility/services/meta.service';
import { WindowRef } from './utility/services/window.service';
import { MixpanelService } from './utility/services/mixpanel.service';
import { FeaturesFlagService } from './utility/services/features-flag.service';
import { LocationService } from './utility/services/location.service';
import { ZohoChatbotService } from './utility/services/zoho-bot.service';

Sentry.init({
  dsn: "https://964503a7631d4d5b8f99480d96afef9d@o69967.ingest.sentry.io/6408418",
  environment: environment.SENTRY_ENV,
  integrations:[
    new Sentry.Integrations.Breadcrumbs({
      console:false
    })
  ]
});
// Create a GrowthBook context

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule
  ],
  providers: [
    DataService,
    CookieService,
    ApiService,
    ThemeService, 
    LoaderService,
    MetaService,
    MixpanelService,
    FeaturesFlagService,
    LocationService,
    ZohoChatbotService,
    WindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    { 
      provide: ErrorHandler, 
      useClass: GlobalErrorHandler
    },
    {
      provide: 'googleTagManagerId', 
      useValue: environment.GTM_ID
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }