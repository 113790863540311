import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {

  constructor() {}

  //set data to local storage
  setCookie(cookie_name:string,cookie_value:any,expiry?:number){
    if(cookie_value){
        let date:Date = new Date();
        let days_to_expire:number=expiry?expiry:2;
        let data = btoa(unescape(encodeURIComponent(JSON.stringify(cookie_value))));
        date.setTime(date.getTime()+(days_to_expire*24*60*60*1000));
        document.cookie = cookie_name + '=' + data + '; expires=' + date.toString();
    }
  }

  //get data from local storage
  getCookie(cookie_name:string){
    let name = cookie_name + '=';
    let all_cookies = document.cookie.split(';');
    let cookie_value:any=null;
    let temp_cookie:any=null;
    for(let i=0; i<all_cookies?.length; i++){
      let temp:string = all_cookies[i].trim();
      if (temp.indexOf(name)==0){
        temp_cookie=temp.substring(name?.length,temp?.length);
        if(cookie_value?.timestamp && cookie_value.timestamp<temp_cookie.timestamp){
          cookie_value=temp_cookie;
        }
        if(!cookie_value){
          cookie_value=temp_cookie;
        }
      }
    }
    if(cookie_value){
        cookie_value = JSON.parse(decodeURIComponent(escape(window.atob(cookie_value))));
    }
    return cookie_value;
  }

  //remove particular data from local storage
  deleteCookie(cookie_name:string){
    document.cookie = cookie_name+'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

}

