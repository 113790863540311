<div class="jw-modal flex" id="modal" (click)="closeOnBackgroundClick($event)" [ngClass]='modal_type' [ngStyle]="details?.layout_styles" [class.confetti]="show_confetti">
  <div class="jw-modal-body" id="jwModalBody" [ngStyle]="details?.body_styles" [ngClass]='max_height_inmobile'>
    <div class="cross-btn" (click)="closeModal(id,null)" *ngIf="!details?.hide_cross" [ngStyle]="details?.cross_btn_styles">
      <svg [attr.class]="'cancel__modal'">
        <use [attr.xlink:href]="'../../../assets/images/icons.svg?32990#cancel'" />
      </svg>
    </div>
    <div *ngIf="details?.heading" class="jw-modal-head">
      {{details?.heading}}
    </div>
    <ng-content></ng-content>
    <div *ngIf="details?.info_btn" class="jw-modal-btn-row center" [ngClass]="{'flex':details?.info_btn_cancel}">
      <button [ngStyle]="details?.full_btn && {'width':'100%'}" (click)="closeModal(id,details?.info_btn_msg)"class="btn btn__confirm">{{this.details?.info_btn}}</button>
      <button *ngIf="details?.info_btn_cancel" [ngStyle]="details?.full_btn && {'width':'100%','margin-top':'15px'}" (click)="closeModal(id,null)" class="btn btn__cancel">{{this.details?.info_btn_cancel}}</button>
    </div>
    <div *ngIf="details?.confirm_btn" class="jw-modal-btn-row flex">
      <button (click)="close()" class="btn btn__cancel">Cancel</button>
      <button  (click)="confirm()"class="btn btn__confirm">{{this.details?.confirm_btn}}</button>
    </div>
  </div>
</div>
<!-- <div class="jw-modal-background" [ngStyle]="details?.modal_bg_styles"></div> -->
