import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ModalService } from './modal.service';

@Component({
    selector: 'jw-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() modal_type: string;
    @Input() details:any;
    @Input() show_confetti: boolean = false;
    @Output() onClick = new EventEmitter<string>();
    private element: any;
    max_height_inmobile:string='max__height--90';

    constructor(private modalService: ModalService,
      @Inject(DOCUMENT) private document: Document,
      private el: ElementRef) {
      this.element = el.nativeElement;
    }

    ngOnInit(): void {
      // ensure id attribute exists
      if (!this.id) {
        return;
      }

      //type of modal
      this.modal_type=this.modal_type?this.modal_type:'popup';

      // move element to bottom of page (just before </body>) so it can be displayed above everything else
      this.document.body.appendChild(this.element);

      // close modal on background click
      this.element.addEventListener('click', el => {
          if (el.target.className === 'jw-modal') {
              this.close();
          }
      });
      // add self (this modal instance) to the modal service so it's accessible from controllers
      this.modalService.add(this);
      //assign min height for drawer panel
      this.max_height_inmobile=this.details&&this.details.max_height_inmobile?this.details.max_height_inmobile:this.max_height_inmobile;
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
      this.modalService.remove(this.id);
      this.element.remove();
    }

    // open modal
    open(): void {
      this.element.style.display = 'block';
      this.document.body.classList.add('jw-modal-open');
    }

    // close modal
    close(): void {
      this.element.style.display = 'none';
      this.document.body.classList.remove('jw-modal-open');
    }

    closeOnBackgroundClick(event:any){
      if (event.target.id === 'modal') {
        this.modalService.close(this.id);
      }
    }

    closeModal(id: string, msg:string) {
      this.modalService.close(id);
      let msg_to_send:string=msg?msg:'close';
      this.onClick.next(msg_to_send);
    }
    //on clicking on confirm
    confirm(){
      this.onClick.next();
    }
}