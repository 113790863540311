import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { slideInAnimation } from './utility/route.animations';
import { RouterOutlet } from '@angular/router';
import { Inject } from '@angular/core';
import { Router , NavigationEnd} from '@angular/router';
import { DataService } from './utility/services/data-sharing.service';
import { MetaService} from './utility/services/meta.service';
import { ApiService} from './utility/services/api.service';
import { FeaturesFlagService } from './utility/services/features-flag.service';
import { ModalService } from './shared/modal';
import { MixpanelService } from './utility/services/mixpanel.service';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from './utility/services/theme.service';

declare global {
  interface Window {
    trackingData: any;
  }
}
declare let fbq:Function;
export function getfbq() {
  let FBQ:any;
  try {
    fbq;
  } catch (e) {
    if (e.name == "ReferenceError") {
      FBQ = false;
    }
  }
  return FBQ === false ? FBQ : fbq;
}
const urls_to_avoid:string[]=['order','not-found','error-unavailable','error-500'];
const urls_to_show_zoho:string[]=['order-summary','track-order','order-history','order-status'];
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'contactless-ordering';
  check_to_set_pixel:boolean=true; 
  check_to_set_gtm:boolean=true;
  previous_url: string = null;
  previous_url_slug: string = null;
  current_url:string=null;
  current_url_slug: string = null;
  routerSubscription$:any=null;
  common_mod_info:any={};
  
  constructor(private dataService:DataService,
    @Inject(DOCUMENT) private doc: any,
    private renderer: Renderer2,
    public router: Router,
    private apiService: ApiService,
    private modalService: ModalService,
    private metaService:MetaService,
    private mixpanelService:MixpanelService,
    private featuresFlagService:FeaturesFlagService,
    private themeService:ThemeService){}

  ngOnInit(){
    this.initialise();
    // feature flag
    this.featuresFlagService.getFeatureFlags();
    if(this.dataService.getDataFromStore('user_auth_token')){
      this.dataService.setData('loggedInStatus',true);
    } else{
      this.dataService.setData('loggedInStatus',false);
    } 
  }

  ngOnDestroy(){
    if(this.routerSubscription$){
      this.routerSubscription$.unsubscribe();
    }
  }

  initialise(){
    this.routerSubscription$=this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd ) {
        let slug:string = event.url.split('/')[1];
        //sbi yono check
        if(event.url.includes('utm_source=sbi_yono')&&event.url.includes('customer_hash=')){
          let customer_hash:string=event.url.split('customer_hash=')[1];
          this.dataService.setDataInStore('opened_on_sbi_yono',customer_hash.split('&')[0]);
          if(!this.dataService.getDataFromStore('user_auth_token')){
            this.loginViaSBIYono(slug.split('?')[0]);
          }
        }
        if(slug.includes('cart_')){
          this.unshortenUrl(slug);
        }else{
          this.previous_url = this.current_url;
          this.previous_url_slug = this.current_url_slug;
          this.current_url = event.url;
          this.current_url_slug = slug.split('?')[0];
          if(this.check_to_set_pixel){
            this.check_to_set_pixel=false;//fb pixel
            this.check_to_set_gtm=false;//gtm
            if(urls_to_avoid.indexOf(this.current_url_slug)===-1){
              this.getMerchantDetails(this.current_url_slug,true);
            }
          }
          if(this.current_url_slug&&this.previous_url_slug&&this.current_url_slug!==this.previous_url_slug && urls_to_avoid.indexOf(this.current_url_slug)===-1){
            this.getMerchantDetails(this.current_url_slug,false);
          }
          if(getfbq()){
            fbq('track','PageView');
            fbq('track','ViewContent');
          }
          //mix panel
          this.mixpanelService.trackEvents('page_view',{'url':event.urlAfterRedirects, 'source_url':this.previous_url});
        }
      }
    });

    //listen for storage event
    window.addEventListener('storage', (event) => {
      if (event.key === 'user_location') {
        // Handle the updated value
       window.location.reload();
        // Use the updated value as needed, e.g., broadcast it across your app
      }
    });
  }

  unshortenUrl(id:string){
    this.apiService.getData('/web/api/v2/get_shorturl_data/',{id:id}).subscribe((result:any)=>{
      if(result.redirect_url && Object.keys(result.redirect_url)?.length){
        if(result.redirect_url.address_id){
          this.dataService.setDataInStore('order_details_'+result.redirect_url.merchant_slug,{id:result.redirect_url.address_id});
        }
        this.dataService.setDataInStore('cart_user_'+result.redirect_url.merchant_slug,result.redirect_url.user_id);
        let cart_id_key:string=result.redirect_url.order_type==='dine_in'?'dine_in_cart_id_':'cart_id_';
        this.dataService.setDataInStore(cart_id_key+result.redirect_url.merchant_slug,result.redirect_url.cart_id);
        this.router.navigateByUrl(`${result.redirect_url.merchant_slug}/view-order/${result.redirect_url.order_type}?utm_campaign=${result.redirect_url.utm_campaign}&utm_medium=${result.redirect_url.utm_medium}&utm_source=${result.redirect_url.utm_source}`);
      }else{
        this.router.navigateByUrl('/not-found');
      }
    }, (error:any) => {
      this.router.navigateByUrl('/not-found');
    });
  }

  getMerchantDetails(slug:string,track_id:boolean){
    let data:any={};
    this.apiService.getData(`/contactless_dining/api/v2/merchants/${slug}/merchant-info`,data).subscribe((result:any)=>{
      if (track_id) {
        this.checkForTrackingId(this.current_url_slug);
      }
      let theme = result.theme?result.theme:{'brand-primary-color': "#E66158"};
      this.themeService.setTheme(theme);
      result['brand_color']=theme['brand-primary-color'];
      this.dataService.setData('merchantData',result);
      if(result.age_verification_required && !this.dataService.getDataFromSession(slug+'_age_verified')){
        this.common_mod_info.heading="Verify your age";
        this.common_mod_info.description="You must be of legal drinking age to access this site.";
        this.common_mod_info.button_text="Yes I am";
        this.common_mod_info.button_msg='yes';
        this.common_mod_info.button_text_cancel="No I am not";
        this.common_mod_info.full_btn=true;
        this.common_mod_info.hide_cross=true;
        this.modalService.open('commonModal');
      }
      //mixpanel
      this.mixpanelService.trackEvents('view_store',{merchant_outlet_id:result.id,merchant_chain_id:result.chain_id});
      // growthbook set attribute 
      this.featuresFlagService.setAttributes(result);
      //update page title
      this.metaService.updateTitle(result.name,result.location);
      if((!result.ordering_type||(result.ordering_type&&result.ordering_type?.length===0))&&!result.dine_in_type){
        this.router.navigate(['/error-unavailable']);
      }
    }, (error:any) => {
      if(error&&error.status===500){
        this.router.navigateByUrl('/error-500');
      }else{
        this.urlUnshortner(slug);
      }
    });
  }

  urlUnshortner(id:string){
    this.apiService.getData(`/web/api/v2/get_shorturl_data`,{id:id}).subscribe((result:any)=>{
      if(result.redirect_url){
        let url_components:Array<string>=result.redirect_url.split('/');
        this.dataService.setDataInStore('feedback_user_auth_token',url_components[3].split('?')[0]);
        this.router.navigate([`${url_components[2]}/feedback/${url_components[3].split('=')[1]}`]);
      }else{
        this.router.navigateByUrl('/not-found');
      }
    },(err)=>{
      this.router.navigateByUrl('/not-found');
    });
  }

  loginViaSBIYono(slug:string){
    this.apiService.postData('/contactless_dining/api/v1/users/sbi_yono_login',{customer_hash:this.dataService.getDataFromStore('opened_on_sbi_yono'), slug:slug}).subscribe((result:any)=>{
      this.dataService.setDataInStore('user_info',result.user);
      this.dataService.setDataInStore('user_auth_token',result.user.token);
      this.dataService.setData('loggedInStatus',true);
    }, (error:any) => {
      // this.router.navigateByUrl('/not-found');
      //error case to be discussed
    });
  }

  closeCommonModal(event:any){
    switch(event){
      case 'yes':
        let slug:string=this.current_url_slug?this.current_url_slug:null;
        this.dataService.setDataInSession(slug+'_age_verified',true);
        break;
      case 'close':
        this.common_mod_info.heading="Oops! You cannot proceed.";
        this.common_mod_info.description="Sorry we don’t serve people under the legal drinking age.";
        this.common_mod_info.button_text=null;
        this.common_mod_info.button_msg=null;
        this.common_mod_info.button_text_cancel=null;
        this.common_mod_info.full_btn=false;
        this.common_mod_info.hide_cross=true;
        this.modalService.open('commonModal');
        break;
    }
  }

  checkForTrackingId(slug:string){
    this.apiService.getData('/contactless_dining/api/v2/merchants/'+slug+'/tracking_ids',null).subscribe((result:any)=>{
      if(result){
        window.trackingData=result;
        if(result.facebook_pixel_id){
          this.addFacebookPixel();
        }
        if(result.gtm_id){
          this.addGTM(result.gtm_id);
        }
      }
    });
  }

  addFacebookPixel(){
    const pixel_script = this.renderer.createElement('script');
    pixel_script.src = './assets/scripts/add-fbpixel-external.js';
    this.renderer.appendChild(this.doc.head, pixel_script);
  }

  addGTM(tracking_id:string){
    const gtm_script = this.renderer.createElement('script');
    gtm_script.src = './assets/scripts/add-gtm.js';
    this.renderer.appendChild(this.doc.head, gtm_script);
    const merchant_gtm_container_body = this.renderer.createElement('noscript');
    this.renderer.setProperty(merchant_gtm_container_body, 'innerHTML',`<iframe src="https://www.googletagmanager.com/ns.html?id='${tracking_id}'"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`);
    this.renderer.insertBefore(this.doc.body,merchant_gtm_container_body,this.doc.body.firstChild);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  checkURL(){
    if(this.router.url === '/order' || this.router.url.match(/\/order\/.*/) || this.router.url.match(/\/order\?utm.*/)){
      return false;
    } else {
      return true;
    }
  }
}