import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DataService } from './data-sharing.service'
import { Merchant, Brand } from '../models/merchant-config.model';

export enum Pages {
  selectOutlet = 'Select Outlet',
  chooseBrand = 'Choose Brand'
};

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  private titleSubject$ = new BehaviorSubject('Thrive');
  private descSubject$ = new BehaviorSubject('Thrive');
  existing_desc: string = '';
  brands = [];
  merchant_details: Merchant;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService
  ) {
    this.dataService.merchantDataReceived.subscribe(info => this.merchant_details = info);
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) { route = route.firstChild; }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((route: {title: string, description: string}) => {
        this.existing_desc = route.description;
        this.titleSubject$.subscribe(title => titleService.setTitle(title + ' | ' + route.title))
        this.descSubject$.subscribe(desc => {
          this.meta.removeTag('name=description');
          setTimeout(() => this.meta.updateTag({ name: 'description', content: desc }), 1000);
        });
      });
  }

  get title() {
    return this.titleSubject$.value;
  }

  updateMetaInfo(content:string) {
    this.meta.updateTag({ name: 'description', content: content });
  }

  getBrands(brands:Array<Brand>) {
    this.brands = brands;
  }

  updateTitle(outlet_name?: string, location?: string) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)).subscribe((event) => {
        if (outlet_name && location) {
          this.titleService.setTitle(outlet_name + ', ' + location + ' ' + event['title']);
        } else {
          this.titleService.setTitle(event['title']);
        }
    });
  }
}