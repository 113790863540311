import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { DataService } from '../services/data-sharing.service';

@Injectable({
    providedIn: 'root'
})
export class CartGuard  {
    constructor(
        private dataService: DataService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot): any {
        if(route?.params?.ordering_type==='dine_in'||route?.routeConfig?.path.includes('dine_in')){
            if (this.dataService.getDataFromStore('dine_in_cart_id_'+route.params.slug)) {
                return true;
            } else {
                this.router.navigate(['/'+route.params.slug+'/browse-menu/dine_in']);
                return false;
            }  
        }else{
            if (this.dataService.getDataFromStore('cart_id_'+route.params.slug)) {
                return true;
            } else {
                this.router.navigate(['/'+route.params.slug]);
                return false;
            }
        }
    }
}
